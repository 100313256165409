<script
    lang="ts"
    setup
>
    import type { AppTooltip } from '~/ts/types/app'

    type Props = {
        tooltipArgs?: AppTooltip
    }

    const props = defineProps<Props>()
</script>

<template>
    <AppTooltip
        ref="tooltipRef"
        width="280"
        close-on-click-outside
        v-bind="props.tooltipArgs"
        content-class="min-h-[78px] flex flex-col items-center justify-center"
    >
        <template #activator="slotProps">
            <slot
                name="activator"
                v-bind="slotProps"
            >
                <div
                    class="flex items-center flex-1 h-[inherit]"
                    @mouseleave.passive="slotProps.close"
                    @click="slotProps.open"
                >
                    <slot name="activator-text" />

                    <AppIconLock
                        size="16"
                        color="#000"
                        class="ml-2"
                    />
                </div>
            </slot>
        </template>

        <slot name="content-wrapper">
            <div class="flex items-center">
                <AppIconPro size="34" />

                <div class="ml-4 text-[14px] leading-[130%]">
                    <slot name="content">
                        <slot name="content-text">
                            {{ $t('pro-tooltip-1') }}
                        </slot>

                        &nbsp;

                        <AppLink
                            class="!inline !text-white"
                            :to="{ name: 'p-pid-billing' }"
                        >
                            <slot name="content-link-text">
                                {{ $t('pro-tooltip-2') }}
                            </slot>
                        </AppLink>
                    </slot>
                </div>
            </div>
        </slot>
    </AppTooltip>
</template>
